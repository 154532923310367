import React from "react"

const CategoryTag: React.FC<TCategoryTagProps> = ({
  category,
  size = "default",
  color = "dark",
}: TCategoryTagProps) => {
  return (
    <>
      {category &&
        category.map((category) => (
          <div
            key={category}
            className={`inline-block font-semibold
            ${color === "dark" && "bg-gray-800 text-white"} 
            ${color === "light" && "bg-gray-200 dark:bg-gray-700 dark:text-white"} 
            ${color === "featured" && "bg-white text-black"} 
            ${size === "small" ? "rounded-md text-xs px-2 py-1 mr-1" : "rounded-lg text-sm px-3 py-2 mr-2"}
            `}
          >
            {category}
          </div>
        ))}
    </>
  )
}

interface TCategoryTagProps {
  category: string[]
  color?: "light" | "dark" | "featured"
  size?: "default" | "small"
}

export default CategoryTag
