import React from "react"
import { graphql } from "gatsby"
import { Post } from "~components/blog/blog-types"
import Seo from "~components/seo"

// UI
import Headline from "~ui/Headline"
import Layout from "~components/layout"
import ArticlePreviewIndex from "~components/blog/article-preview-index"
import BlogPreFooter from "~components/blog/pre-footer"
import ArticleFeatured from "~components/blog/article-featured"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"

const RootIndex: React.FC<{
  data: { allContentfulBlogPost: { nodes: Post[] }; postsByCategory: { nodes: Post[] }; featured: { nodes: Post[] } }
  pageContext
}> = (props) => {
  const { category } = props.pageContext
  const allPosts: Post[] = category?.name ? props.data.postsByCategory?.nodes : props.data.allContentfulBlogPost?.nodes
  const [featuredPost]: Post[] = props.data.featured?.nodes

  const posts: Post[] = allPosts.filter((p: Post) => {
    return p.title !== featuredPost?.title
  })

  {
    return (
      <Layout blog>
        <Seo
          canonicalTag="https://canopyservicing.com/blog/"
          title={category?.title ?? "Canopy Servicing - Company News & Industry Insights"}
          description={
            category?.desc ??
            "The Canopy blog explores the next generation of credit and lending products and the role played by Canopy Servicing."
          }
        />
        {featuredPost && (
          <div>
            <Spirograph
              shape={1}
              width="w-full1/4x sm:w-3/4 xl:w-3/5"
              position="right-0"
              offsetY="-translate-y-1/20 sm:-translate-y-1/20 2xl:-translate-y-1/10"
              offsetX="translate-x-1/2 sm:translate-x-1/3 md:translate-x-2/5"
            />
            <Aura
              size="w-full2x sm:w-full1/2x md:w-full"
              position="right-0"
              offsetY="-translate-y-1/2 sm:-translate-y-1/3"
              offsetX="translate-x-1/2"
              opacity="opacity-50"
            />
            <Aura
              color="purple"
              size="w-full1/2x md:w-full"
              position="right-0"
              offsetY="-translate-y-3/20 md:-translate-y-1/20"
              offsetX="translate-x-1/2 md:translate-x-3/5"
              opacity="opacity-30"
            />
            <div className="px-6 md:px-12 xl:px-48 2xl:px-56 pt-10 md:pt-16" data-sal="slide-up">
              <Headline level={1} textSize="text-3xl md:text-5xl xl:text-6xl" className="mb-6 md:mb-12">
                Blog <span className="text-blue-500 font-light">{category ? category.name : "All"}</span>
              </Headline>
            </div>
            <ArticleFeatured post={featuredPost} />
          </div>
        )}
        <div className="px-6 md:px-12 xl:px-48 2xl:px-56 pt-12 md:pt-24 pb-4 md:pb-16 relative z-1">
          <Headline level={2} className="mb-6 md:mb-12" data-sal="slide-up" data-sal-delay="200">
            Latest Articles <span className="text-blue-500 font-light">{category ? category.name : "All"}</span>
          </Headline>
          <ArticlePreviewIndex posts={posts} />
        </div>
        <BlogPreFooter />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($categoryName: String) {
    allContentfulBlogPost(sort: { fields: [createdOn], order: DESC }) {
      nodes {
        ...blogPostFields
        postImage {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 800, height: 480, quality: 100)
        }
      }
    }
    postsByCategory: allContentfulBlogPost(
      filter: { category: { eq: $categoryName } }
      sort: { fields: [createdOn], order: DESC }
    ) {
      nodes {
        ...blogPostFields
        postImage {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 800, height: 480, quality: 100)
        }
      }
    }
    featured: allContentfulBlogPost(
      filter: { featured: { eq: true }, category: { eq: $categoryName } }
      sort: { fields: [createdOn], order: DESC }
      limit: 1
    ) {
      nodes {
        ...blogPostFields
        postImage {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 1800, height: 960, quality: 100)
        }
      }
    }
  }
`
