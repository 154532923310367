import React from "react"
import { Link } from "gatsby"

// UI
import Headline from "~ui/Headline"
import CategoryTag from "~components/blog/category-tag"
import { GatsbyImage } from "gatsby-plugin-image"
import { Post } from "./blog-types"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const ArticleFeatured: React.FC<{ post: Post }> = ({ post }) => {
  return (
    <div className="container mx-auto">
      <article className="mx-6 md:mx-0 fs-unmask" data-sal="slide-up" data-sal-delay="100">
        <Link
          to={`/blog/${post.urlSlug}/`}
          className="block relative bg-gray-800 shadow-2xl rounded-3xl overflow-hidden"
        >
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <GatsbyImage
              alt={post.postImage.description}
              image={post.postImage.gatsbyImageData}
              className="w-full aspect-3/2 col-span-2"
            />
            <div className="bg-blue-500 flex justify-between flex-col w-full px-6 md:px-12 py-6 md:py-10">
              <div className="text-sm">
                <CategoryTag category={["Featured"]} color="featured" />
                <CategoryTag category={post.category} />
              </div>
              <div>
                <Headline level={2} textSize="text-2xl md:text-4xl 2xl:text-5xl" className="text-white my-6">
                  {post.extendedTitle && (
                    <span className="block text-base md:text-lg text-white mb-1 md:mb-2">{post.extendedTitle}</span>
                  )}
                  {post.title}
                </Headline>
                <p className="hidden lg:block font-bold text-white pb-6">
                  Read More <Icon icon={faArrowRight} className="ml-1" />
                </p>
              </div>
              <div className="text-sm text-blue-300">
                <time dateTime={post.rawDate}>{post.createdOn}</time> &nbsp;&middot;&nbsp; By {post.author}
              </div>
            </div>
          </div>
        </Link>
      </article>
    </div>
  )
}

export default ArticleFeatured
