import React from "react"
import { Link } from "gatsby"

// UI
import Headline from "~ui/Headline"
import CategoryTag from "~components/blog/category-tag"
import { GatsbyImage } from "gatsby-plugin-image"
import { Post } from "./blog-types"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const ArticlePreviewIndex: React.FC<{ posts: Post[] }> = ({ posts }) => {
  return (
    <>
      {posts.map((post) => (
        <article key={post.urlSlug} data-sal="slide-up">
          <Link to={`/blog/${post.urlSlug}/`} className="block md:flex mb-12 md:mb-16 lg:mb-20 fs-unmask">
            <div className="relative flex-1 md:mr-8 bg-gray-800 shadow-2xl rounded-3xl overflow-hidden">
              <GatsbyImage
                alt={post.postImage.description}
                image={post.postImage.gatsbyImageData}
                className="w-full h-full rounded-3xl overflow-hidden"
                imgStyle={{ borderRadius: 16 }}
              />
              {post.category && (
                <div className="absolute bottom-3 md:top-3 md:bottom-auto left-3">
                  <CategoryTag category={post.category} size="small" />
                </div>
              )}
            </div>
            <div className="flex-1 md:ml-8">
              <div className="text-sm text-gray-500 mb-2 md:mb-4 mt-6 md:mt-0">
                <time dateTime={post.rawDate}>{post.createdOn}</time> &nbsp;•&nbsp; By {post.author}
              </div>
              <Headline level={2} textSize="text-2xl md:text-4xl">
                {post.title}
              </Headline>
              <p className="mb-4">{post.seoDescription}</p>
              <span className="font-bold text-blue-500">
                Read More <Icon icon={faArrowRight} className="ml-1" />
              </span>
            </div>
          </Link>
        </article>
      ))}
    </>
  )
}

export default ArticlePreviewIndex
