import React from "react"
import { Link } from "gatsby"

// UI
import Headline from "~ui/Headline"
import SectionCallout from "~components/SectionCallout"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faComments, faCode } from "@fortawesome/pro-thin-svg-icons"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const PreFooterItem = (props) => (
  <div className={`lg:flex items-center ${props.className}`}>
    <div className="bg-purple-700 rounded-3xl h-24 lg:h-28 w-24 lg:w-28 flex justify-center items-center mr-8 mb-6 lg:mb-0">
      <Icon icon={props.icon} size="3x" />
    </div>
    <div className="flex-1">
      <Headline level={4} className="text-white mb-2 lg:mb-3">
        {props.headline}
      </Headline>
      {props.children}
    </div>
  </div>
)

const BlogPreFooter = (): JSX.Element => (
  <SectionCallout>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12 xl:gap-16 text-white items-center fs-unmask">
      <div>
        <Headline level={3} textSize="text-3xl md:text-4xl" className="text-white mb-3 md:mb-4">
          What is Canopy?
        </Headline>
        <p className="lg:text-lg">
          We believe in building technology that helps facilitate better life experiences. Financial products have
          largely been the same, lacking transparency and control. Canopy is built for developers to launch and service
          financial products in the most flexible way.
        </p>
      </div>
      <div>
        <PreFooterItem headline="Servicing is our sweet spot" icon={faComments} className="mb-8">
          <p>
            Work with our experts to optimize your customer ops.{" "}
            <Link to="/contact/" className="pl-2 2xl:pl-0 font-bold">
              Contact Us <Icon icon={faArrowRight} className="ml-1" />
            </Link>
          </p>
        </PreFooterItem>
        <PreFooterItem headline="We're Hiring" icon={faCode}>
          <p>
            We&rsquo;re looking for people looking for work that matters.{" "}
            <a href="https://jobs.lever.co/canopyservicing/" className="pl-2 2xl:pl-0 font-bold">
              Careers <Icon icon={faArrowRight} className="ml-1" />
            </a>
          </p>
        </PreFooterItem>
      </div>
    </div>
  </SectionCallout>
)

export default BlogPreFooter
